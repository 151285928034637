<template>
  <el-dialog :title=" '修改表单'" :close-on-click-modal="false" :visible.sync="visible">
    <el-dialog :title=" '添加字段'" :close-on-click-modal="false" :visible.sync="addVisible" append-to-body @close="getdata">

        <div class="">
            <el-form ref="df">


                <el-form-item>
                    <el-input v-model="manualEntryFieldName" placeholder="请输入字段名" class="inputClass"></el-input>
                    <el-select v-model="manualEntryFieldType" placeholder="请选择字段种类">
                        <el-option
                            v-for="item in fieldTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">

                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addManualEntryField()">创建</el-button>

                </el-form-item>
            </el-form>

    </div>
    </el-dialog>

    <div class="">
        <el-form label-width="80px">

            <el-button type="primary" icon="el-icon-plus" class="addButton" @click="openAddManualEntryField">添加字段</el-button>

            <el-table border
                :data="inputDataForm"
                ref="tb"
            >
                <el-table-column  prop="manualEntryFieldName" header-align="center" align="center" label="字段名称">
                </el-table-column>
                <el-table-column  prop="manualEntryFieldType" header-align="center" align="center" label="字段种类">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.manualEntryFieldType === 1" size="small">文本</el-tag>
                        <el-tag v-else size="small">文件</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                    <el-button type="text" @click="removeField(scope.row.id)" class="warningButton">删除</el-button>
                    </template>
                </el-table-column>

            </el-table>
        </el-form>
        <!-- <AddManualEntryField v-if="AddManualEntryFieldFlag" ref="addManualEntryField" :addManualEntryId="addManualEntryId" @close="getData"></AddManualEntryField> -->
  </div>
  </el-dialog>

</template>

<script>
// import AddManualEntryField from './addManualEntryField';//新增字段

export default {
  props:{
      manualEntryEditId:{
          type:Number,
          required:true
      }
  },
  name:'Simple',
  components:{
    // AddManualEntryField
  },
  data() {
    return {
      newManualEntryId:0,
      visible: false,
      code:'',
      fileList: [],
      inputDataForm:[

      ],
      AddManualEntryFieldFlag:false,
      addVisible:false,
      addManualEntryId:0,

      fieldTypes:[
          {value:'1',label:'文本'},
        //   {value:'2',label:'文件'}
      ],
      manualEntryFieldName:'',
      manualEntryFieldType:null
    };
  },
  watch:{
      manualEntryEditId(newValue,oldValue){
        this.newManualEntryId=newValue
        this.addManualEntryId=newValue
      },
  },
  created(){
      this.newManualEntryId=this.manualEntryEditId;
      this.addManualEntryId=this.manualEntryEditId;

  },
  activated(){

  },
  mounted(){

  },

  methods: {
    init() {
      console.log(this.newManualEntryId);
      this.inputDataForm=[];
      this.formMessage=[];
      this.manualEntryFieldName='';
      this.manualEntryFieldType=null;
      this.getData();

    },
    getData(){
        this.getRequest(
            "/manualEntry/getFieldInfo/?manualEntryId=" +
            this.newManualEntryId
        ).then((resp) => {
            if (resp) {
            this.inputDataForm = resp.data;
            this.visible=true;
            }
        });
    },
    //打开新增字段弹窗
    openAddManualEntryField(){
        /* this.AddManualEntryFieldFlag=true;
        this.$nextTick(() => {
        this.$refs.addManualEntryField.init();
      }); */
      this.manualEntryFieldName='';
      this.manualEntryFieldType=null;
      this.addVisible=true;
    },
    //添加字段
    addManualEntryField(){
        //判断是信息是否完整
        var complete=true;
        if(this.manualEntryFieldName==''||this.manualEntryFieldType==null){
            complete=false;
        }
        if(!complete){
            //如果有未填的信息，弹框提示
            this.$message({
                message:'请补全信息',
                type:'error'
            });
        }else{
            let data={
                manualEntryId:this.newManualEntryId,
                manualEntryFieldName:this.manualEntryFieldName,
                manualEntryFieldType:this.manualEntryFieldType
            }
            this.postRequest("/manualEntry/addField",data).then(response=> {
              if (response) {
                this.getData();
                this.addVisible=false;
                this.$emit("initDataByEdit")
              }

            }).then(error => {

            })
        }


    },
    //删除字段
    removeField(id){
        console.log("要删除的fieldid为"+id);
        this.$confirm('此操作将删除该表单, 是否继续?','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.getRequest(
          "/manualEntry/deleteField/?manualEntryFieldId=" +
          id + "&manualEntryId=" + this.newManualEntryId
        ).then((resp) => {
          this.getData();
          if (resp) {
          }
        });
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
      });
    },


    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePreview(file) {
        console.log(file);
    },
    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
      // 文件改变时
    /* fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    }, */
  },
};
</script>
<style scoped>
.el-input {
    margin-right: 10px;
}
.el-select {
    width: 250px;
    margin-right: 10px;
}
.warningButton{
  color: #F56C6C;
}
.addButton{
    margin-bottom: 15px;
}
</style>
